import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios"

export interface ApiResponse {
  status: 0 | 1;
  data?: any;
  message?: string;
}

const HttpClient: AxiosInstance = axios.create({
  baseURL: 'https://api.battlexo.com/api/v1'
})

function handleResponse(apiResponse: AxiosResponse<ApiResponse>) {
  return apiResponse.data
}

function handleError(errorResponse: AxiosError<ApiResponse>): ApiResponse {
  if (errorResponse.response) {
    return errorResponse.response.data
  }
  else if (errorResponse.request) {
    return {
      status: 0,
      message: 'No response from server'
    }
  }
  else {
    return {
      status: 0,
      message: errorResponse.message
    }
  }
}

export function addCardRequest(payload: any) {
  return HttpClient.post('/core/xocard/request', payload)
    .then(handleResponse)
    .catch(handleError)
}
