import { useState } from 'react';
import './App.css';
import { addCardRequest } from './service';

function App() {
  const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [reason, setReason] = useState('')
  const [nameError, setNameError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [reasonError, setReasonError] = useState('')
  const [submit, setSubmit] = useState(false)

  function isEmail(email: string) {
    if (!email) return false
    return emailRe.test(email.toLowerCase())
  }

  function valiadteInput(type: string) {
    if (type === 'name') {
      if (!name?.trim()) {
        setNameError('Name Required')
      }
    }
    if (type === 'phone') {
      if (!phone?.trim()) {
        setPhoneError('Phone Number Required')
      }
      else if (phone?.length > 10 || phone.length < 10) {
        setPhoneError('Invalid Phone Number')
      }
      else if (!/^[\d]+$/.test(phone)) {
        setPhoneError('Invalid Phone Number')
      }
    }
    if (type === 'email') {
      if (!email?.trim()) {
        setEmailError('Email Required')
      }
      else if (!isEmail(email)) {
        setEmailError('Invalid Email ID')
      }
    }
    if (type === 'reason') {
      if (!reason?.trim()) {
        setReasonError('Reason Required')
      }
    }
  }

  function handleName(val: string) {
    setNameError('')
    setName(val)
  }

  function handlePhone(val: string) {
    setPhoneError('')
    setPhone(val)
  }

  function handleEmail(val: string) {
    setEmailError('')
    setEmail(val)
  }

  function handleReason(val: string) {
    setReasonError('')
    setReason(val)
  }

  function requestNew() {
    setEmail('')
    setEmailError('')
    setName('')
    setNameError('')
    setReason('')
    setReasonError('')
    setPhone('')
    setPhoneError('')
    setSubmit(false)
  }

  function handleSubmit() {
    if (!name.trim() || !phone.trim() || !email.trim() || !reason.trim()) {
      valiadteInput('name')
      valiadteInput('phone')
      valiadteInput('email')
      valiadteInput('reason')
    } else {
      addCardRequest({
        name: name.trim(),
        reason: reason.trim(),
        phone: phone.trim(),
        email: email.trim()
      }).then(res => {
        if (res.status) {
          setSubmit(true)
        }
      })
    }
  }

  return (
    <div className="main">
      <header className="main-header">
        <img src="/logo.svg" className="" alt="BattleXO Logo" />
      </header>
      <div className="form-wrapper">
        {
          submit ?
            <>
              <div className='success'>
                <h1>Request Submited</h1>
                <button className='more' onClick={requestNew}>Request New</button>
              </div>
            </>
            :
            <>
              <h1 className='card-heading'>Pre-Register for XO Pay Card</h1>
              <div className="form-control-wrapper">
                <div className="form-control">
                  <label htmlFor="name">Name</label>
                  <input
                    id='name'
                    type="text"
                    value={name}
                    onChange={ev => handleName(ev.target.value)}
                    onBlur={() => valiadteInput('name')}
                  />
                  <p className='error'>{nameError}</p>
                </div>
                <div className="form-control">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    id='phone'
                    type="text"
                    value={phone}
                    onChange={ev => handlePhone(ev.target.value)}
                    onBlur={() => valiadteInput('phone')}

                  />
                  <p className='error'>{phoneError}</p>
                </div>
                <div className="form-control">
                  <label htmlFor="email">Email</label>
                  <input
                    id='email'
                    type="text"
                    value={email}
                    onChange={ev => handleEmail(ev.target.value)}
                    onBlur={() => valiadteInput('email')}
                  />
                  <p className='error'>{emailError}</p>
                </div>
                <div className="form-control">
                  <label htmlFor="reason">Reason</label>
                  <textarea
                    id='reason'
                    rows={3}
                    value={reason}
                    onChange={ev => handleReason(ev.target.value)}
                    onBlur={() => valiadteInput('reason')}
                  />
                  <p className='error'>{reasonError}</p>
                </div>
                <button className='submit' onClick={handleSubmit}>Submit</button>
              </div>
            </>
        }
      </div>
    </div>
  );
}

export default App;
